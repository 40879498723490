var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
    _c("div", [
      _c("section", { staticClass: "product__wrapper" }, [
        _c(
          "div",
          { staticClass: "product__image" },
          [
            _c(
              "swiper",
              { attrs: { options: _vm.swiperOption } },
              [
                _vm._l(_vm.productThumb, function (item) {
                  return item != null
                    ? _c("swiper-slide", [
                        _c("img", {
                          staticClass: "swiper-lazy",
                          attrs: { src: item },
                        }),
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-pagination",
                  attrs: { slot: "pagination" },
                  slot: "pagination",
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "product__explanation" }, [
          _c("h2", { staticClass: "product__title" }, [
            _vm._v(_vm._s(_vm.item.product_name_no_size)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "product__text" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.item.product_subname) +
                "\n          "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product__purchase-unit" }, [
            _c("div", { staticClass: "product__price" }, [
              _vm._v(
                _vm._s(_vm._f("number_format")(_vm.item.price)) +
                  "円/" +
                  _vm._s(_vm.item.product_unit) +
                  "（税込）"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "button__wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "button__inc-dec",
                  attrs: { type: "button", disabled: !_vm.productQuantity },
                  on: { click: _vm.cartOut },
                },
                [
                  _c("img", {
                    attrs: { src: "/img/icon/icon_minus.svg", alt: "減らす" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button__number",
                  class: { notZero: _vm.productQuantity > 0 },
                  attrs: { type: "button" },
                },
                [_vm._v(_vm._s(_vm._f("number_format")(_vm.productQuantity)))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button__inc-dec",
                  attrs: { type: "button" },
                  on: { click: _vm.cartIn },
                },
                [
                  _c("img", {
                    attrs: { src: "/img/icon/icon_plus.svg", alt: "増やす" },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "dl",
            { staticClass: "product__detail" },
            [
              _c(
                "el-collapse",
                { staticClass: "product__detail", attrs: { accordion: "" } },
                [
                  _c(
                    "el-collapse-item",
                    {
                      staticClass: "product__detail-title",
                      attrs: { title: "商品詳細" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.nl2br(_vm.item.product_description)
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }