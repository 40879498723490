var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "input-address": "" } },
    [
      _vm.loading ? _c("Loading", { attrs: { text: _vm.text } }) : _vm._e(),
      _vm._v(" "),
      _c(
        "el-collapse",
        [
          _c(
            "el-collapse-item",
            { attrs: { title: _vm.addTitle, name: "1" } },
            [
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("郵便番号")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.zip,
                        expression: "shipping.zip",
                      },
                    ],
                    attrs: { type: "tel", "input-zip": "", maxlength: "7" },
                    domProps: { value: _vm.shipping.zip },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "zip", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      attrs: { "zip-address": "" },
                      on: { click: _vm.zipToAddress },
                    },
                    [_vm._v("〒検索")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("都道府県")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.pref,
                        expression: "shipping.pref",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.shipping.pref },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "pref", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("市区郡町村")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.city,
                        expression: "shipping.city",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.shipping.city },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "city", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("住所")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.street,
                        expression: "shipping.street",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.shipping.street },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "street", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("番地")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.address,
                        expression: "shipping.address",
                      },
                    ],
                    attrs: { type: "text", placeholder: "1-1-1" },
                    domProps: { value: _vm.shipping.address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "address", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [
                    _vm._v("建物名"),
                    _c("br"),
                    _vm._v("部屋番号等"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.addition,
                        expression: "shipping.addition",
                      },
                    ],
                    attrs: { type: "text", placeholder: "〇〇アパート 504" },
                    domProps: { value: _vm.shipping.addition },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "addition", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("電話番号")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.tel_1,
                        expression: "shipping.tel_1",
                      },
                    ],
                    attrs: { type: "tel", maxlength: "5" },
                    domProps: { value: _vm.shipping.tel_1 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "tel_1", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { attrs: { "tel-hyphen": "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.tel_2,
                        expression: "shipping.tel_2",
                      },
                    ],
                    attrs: { type: "tel", maxlength: "4" },
                    domProps: { value: _vm.shipping.tel_2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "tel_2", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { attrs: { "tel-hyphen": "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.tel_3,
                        expression: "shipping.tel_3",
                      },
                    ],
                    attrs: { type: "tel", maxlength: "4" },
                    domProps: { value: _vm.shipping.tel_3 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "tel_3", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "input-conponent": "", "conponent-split": "" } },
                [
                  _c("label", [_vm._v("お届け先名")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shipping.name,
                        expression: "shipping.name",
                      },
                    ],
                    attrs: { type: "text", placeholder: "お届け先名" },
                    domProps: { value: _vm.shipping.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shipping, "name", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  attrs: {
                    "send-btnarea": "",
                    "pt-3i": "",
                    "pb-3i": "",
                    "conponent-split": "",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      attrs: { "btn-default": "", "mr-1": "" },
                      on: { click: _vm.deleteShipping },
                    },
                    [_vm._v("削除する")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      attrs: {
                        "btn-white": "",
                        "ml-1": "",
                        disabled: _vm.isUpdate,
                      },
                      on: { click: _vm.updateAddress },
                    },
                    [_vm._v("この内容で更新")]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }