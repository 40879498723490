var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "__nuxt-error-page" }, [
    _c("div", { staticClass: "error" }, [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "90",
            height: "90",
            fill: "#DBE1EC",
            viewBox: "0 0 48 48",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.message))]),
      _vm._v(" "),
      _vm.statusCode === 404
        ? _c(
            "p",
            { staticClass: "description" },
            [
              typeof _vm.$route === "undefined"
                ? _c("a", { staticClass: "error-link", attrs: { href: "/" } })
                : _c(
                    "NuxtLink",
                    { staticClass: "error-link", attrs: { to: "/" } },
                    [_vm._v("Back to the home page")]
                  ),
            ],
            1
          )
        : _c("p", { staticClass: "description" }, [
            _vm._v(
              "An error occurred while rendering the page. Check developer tools console for details."
            ),
          ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://nuxtjs.org",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_vm._v("Nuxt")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }