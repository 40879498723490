var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("ul", { staticClass: "footer__link" }, [
      _c(
        "li",
        { class: { current: _vm.selectedMenuItem == _vm.menuItem.shop } },
        [
          _c("img", {
            attrs: { src: "/img/icon/icon_product.svg", alt: "" },
            on: {
              click: function ($event) {
                return _vm.go(_vm.menuItem.shop)
              },
            },
          }),
          _vm._v("\n      商品一覧\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        { class: { current: _vm.selectedMenuItem == _vm.menuItem.mypage } },
        [
          _c("img", {
            attrs: { src: "/img/icon/icon_person.svg", alt: "" },
            on: {
              click: function ($event) {
                return _vm.go(_vm.menuItem.mypage)
              },
            },
          }),
          _vm._v("\n      マイページ\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        { class: { current: _vm.selectedMenuItem == _vm.menuItem.info } },
        [
          _c("img", {
            attrs: { src: "/img/icon/icon_information.svg", alt: "" },
            on: {
              click: function ($event) {
                return _vm.go(_vm.menuItem.info)
              },
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("インフォメーション")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }