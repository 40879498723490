var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
    _c("div", { attrs: { fullscreenloading: "" } }, [
      _c("div", { staticClass: "breeding-rhombus-spinner" }, [
        _c("div", { staticClass: "rhombus child-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-4" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-5" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-6" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-7" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus child-8" }),
        _vm._v(" "),
        _c("div", { staticClass: "rhombus big" }),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { "mt-2": "" } }, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }